/** @jsx jsx */
// @ts-check
import BaseExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { jsx } from 'theme-ui';

export * from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';

export default function ExhibitorsPage(props) {
  return (
    <BaseExhibitorsPage
      {...props}
      withEventBanner
      withEventBannerOverlay={false}
    />
  );
}
